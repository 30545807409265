.contact-section {
  padding: 0 0 104px 0;
}
.contact-content {
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
}
.contact-img {
  width: 531px;
}
.contact-list {
  list-style: none;
  padding-left: 5px;
  margin: 0;
}

.contact-item {
  padding-left: 80px;
  padding-top: 18px;
  padding-bottom: 18px;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.contact-item__img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-item__title {
  text-transform: uppercase;
  color: var(--title-color);
  font-size: 24px;
  font-weight: 500;
}

.contact-item__data {
  color: var(--text-color-light);
  font-size: 20px;
  font-weight: 500;
}

.contact-img {
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .contact-img {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) {
  .contact-section {
    padding-top: 68px;
  }
  .contact-content {
    flex-direction: row;
  }
  .contact-item__title {
    font-size: 36px;
  }
  .contact-item__data {
    font-size: 30px;
  }
  .contact-img {
    width: 390px;
  }
}

@media screen and (min-width: 1560px) {
  .contact-content {
    margin-top: 115px;
  }
  .contact-list {
    padding-left: 24px;
  }
  .contact-item {
    padding: 32px 24px 32px 158px;
    margin-bottom: 24px;
  }
  .contact-item__img {
    width: 135px;
    height: 135px;
  }
  .contact-item__title {
    font-size: 44px;
  }
  .contact-item__data {
    font-size: 35px;
  }
  .contact-img {
    width: auto;
  }
}
