.aboutus {
  padding-top: 32px;
  background: linear-gradient(180deg, #084c84, #1e84d5);
}

.aboutus__container {
  flex-direction: column-reverse;
}

.aboutus__image {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  width: 90%;
  max-width: 318px;
}

.aboutus__bg {
  display: none;
  background-size: contain;
  z-index: 0;
  position: absolute;
  background-image: url(../../../assets/images/Path_3.png);
  background-repeat: no-repeat;
  background-position: center bottom;
}

.aboutus__img {
  width: 100%;
  position: relative;
  z-index: 1;
}

.aboutus__content {
  position: relative;
  z-index: 1;
}

.aboutus__title {
  line-height: 1.6;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  color: var(--white);
  margin-bottom: 0;
  margin-top: 0;
}

.aboutus__content p {
  margin: 1.4em 0;
  line-height: 1.6;
  font-size: 18px;
  text-align: center;
  color: var(--white);
}

.aboutus__download {
  display: flex;
  flex-direction: column;
  margin-bottom: 58px;
  margin-top: 22px;
}

.aboutus__download > a:not(:first-child) {
  margin-top: 1rem;
}

.aboutus__download-android,
.aboutus__download-googleplay {
  display: flex;
  font-size: 18px;
  padding: 8px 16px 8px 28px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 240px;
  font-weight: bold;
  color: var(--first-color);
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 84px;
  box-shadow: 0px 3px 35px 0px rgba(7, 66, 130, 0.56);
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  height: 36px;
}
.aboutus__download-android:hover,
.aboutus__download-googleplay:hover {
  color: var(--first-color-alt);
  box-shadow: 0px 3px 55px 0px rgba(7, 66, 130, 0.96);
}

.aboutus__download-android img,
.aboutus__download-googleplay img {
  width: 36px;
  height: 36px;
}

.aboutus__download-android i {
  margin-left: 0.5rem;
  font-size: 24px;
}

@media screen and (min-width: 992px) {
  .aboutus {
    /* adjust fixed header */
    padding-top: 148px;
    margin-top: -80px;
  }
  .aboutus > .container {
    flex-direction: row;
  }
  .aboutus__image {
    margin-right: 45px;
  }
  .aboutus__bg {
    display: block;
    bottom: -42px;
    left: -120px;
    width: 751px;
    height: 585px;
    background-size: 751px 585px;
  }
  .aboutus__title {
    font-size: 36px;
    text-align: initial;
  }
  .aboutus__content p {
    font-size: 24px;
    text-align: initial;
  }

  .aboutus__download {
    margin-bottom: 108px;
    margin-top: 22px;
    flex-direction: row;
  }

  .aboutus__download > a:not(:first-child) {
    margin-top: 0;
  }

  .aboutus__download-android,
  .aboutus__download-googleplay {
    margin-right: 1.5rem;
    max-width: 240px;
    height: 48px;
    display: inline-flex;
    font-size: 24px;
    padding: 8px 32px 8px 44px;
    margin-left: 0;
    width: auto;
  }
  .aboutus__download-android img .aboutus__download-googleplay img {
    width: 54px;
    height: 54px;
  }

  .aboutus__download-android i {
    font-size: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .aboutus__image {
    max-width: 340px;
    margin-right: 70px;
  }
  .aboutus__title {
    font-size: 42px;
  }
  .aboutus__content p {
    font-size: 28px;
  }
  .aboutus__download {
    margin-top: 45px;
  }
  .aboutus__download-android,
  .aboutus__download-googleplay {
    max-width: 280px;
    font-size: 28px;
    padding: 10px 36px 10px 48px;
  }
  .aboutus__download-android img,
  .aboutus__download-googleplay img {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 1560px) {
  .aboutus {
    /* adjust fixed header */
    padding-top: 180px;
    margin-top: -100px;
  }
  .aboutus__image {
    max-width: 422px;
    margin-right: 74px;
    margin-bottom: 34px;
  }
  .aboutus__title {
    font-size: 52px;
  }
  .aboutus__content p {
    font-size: 36px;
  }
  .aboutus__download-android,
  .aboutus__download-googleplay {
    height: 58px;
    max-width: 340px;
    font-size: 36px;
    padding: 12px 40px 12px 54px;
  }
  .aboutus__download-android img,
  .aboutus__download-googleplay img {
    width: 68px;
    height: 68px;
  }
  .aboutus__download-android i {
    font-size: 38px;
  }
}
