.footer {
  position: relative;
  z-index: 1;
}
.footer__content {
  padding-top: 0;
  background-color: var(--first-color-deep-dark);
}

.footer__top {
  height: 145px;
  background-image: url(../../assets/images/Footer_5.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.footer__content > .container,
.footer__links,
.footer__buttons {
  flex-direction: column;
  align-items: center;
}

.footer__links,
.footer__buttons {
  margin-bottom: 24px;
}
.footer__link {
  color: var(--white);
  font-size: 18px;
  transition: 0.3s;
  margin-bottom: 28px;
}
body.delete-account .footer__link--delect-account,
body.terms .footer__link--terms,
body.privacy .footer__link--privacy,
.footer__link:hover {
  color: var(--first-color-alt);
}

.footer__link ~ .footer__link {
  margin-left: 0;
}

.footer__buttons > a {
  border: 1px solid transparent;
  margin-left: 0;
  margin-bottom: 28px;
}

.footer__buttons > a:hover {
  color: var(--first-color-alt);
  transition: 0.3s;
  border: 1px solid var(--first-color-alt);
  box-shadow: 0 0 4px var(--first-color-alt);
}

.button-googleplay {
  background-color: black;
  padding: 4px 10px;
  overflow: hidden;
  display: inline-flex;
  border-radius: 4px;
}

.button-download {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  color: var(--first-color);
  font-size: 16px;
  height: 62px;
  padding: 0 32px;
  background-color: var(--white);
}

.button-download i {
  font-size: 28px;
  font-weight: 300;
  margin-left: 20px;
}

@media screen and (min-width: 992px) {
  .footer__top {
    background-size: 100% 100%;
  }
  .footer__content {
    padding: 52px 0;
  }
  .footer__content > .container,
  .footer__links,
  .footer__buttons {
    flex-direction: row;
  }
  .footer__buttons > a {
    margin-left: 24px;
    margin-bottom: 0;
  }
  .footer__links,
  .footer__buttons {
    margin-bottom: 0;
  }
  .footer__link {
    margin-bottom: 0;
    font-size: 20px;
  }
  .footer__link ~ .footer__link {
    margin-left: 32px;
  }
  .button-download {
    font-size: 19px;
  }
}

@media screen and (min-width: 1560px) {
  .footer__link {
    font-size: 24px;
  }
  .button-download {
    font-size: 22px;
  }
}
