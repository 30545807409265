.agreement {
  position: relative;
}

.agreement-content {
  position: relative;
  z-index: 1;
}

.agreement-content p {
  color: var(--text-color);
  font-size: 23px;
}

@media screen and (max-width: 767px) {
  .agreement-content p {
    font-size: 15px;
  }
  .agreement-content h3 {
    font-size: 16px;
  }
}
