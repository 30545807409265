html {
    overflow-x: hidden;
    -webkit-text-size-adjust: none;
    font-size: 14px;
  }
  
  body {
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  h2 {
    text-align: center;
  }
  
  p {
    margin-top: 0.5rem;
  }
  
  .editor-content-view p,
  .editor-content-view li {
    white-space: pre-wrap;
  }
  
  .editor-content-view blockquote {
    border-left: 8px solid #d0e5f2;
    padding: 10px 10px;
    margin: 10px 0;
    background-color: #f1f1f1;
  }
  
  .editor-content-view code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
    border-radius: 3px;
  }
  .editor-content-view pre>code {
    display: block;
    padding: 10px;
  }
  
  .editor-content-view table {
    border-collapse: collapse;
  }
  .editor-content-view td,
  .editor-content-view th {
    border: 1px solid #ccc;
    min-width: 50px;
    height: 20px;
  }
  .editor-content-view th {
    background-color: #f1f1f1;
  }
  
  .editor-content-view ul,
  .editor-content-view ol {
    padding-left: 20px;
  }
  
  .editor-content-view input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .editor-content-view img{
    cursor: default;
    display: inline !important;
    max-width: 100%;
  }
  