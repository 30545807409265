.navbar {
  background-color: var(--first-color-dark);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  /* box-shadow: 0 0px 12px rgba(0, 0, 0, 0.7); */
}

.navbar.shadow {
  box-shadow: 0 0 12px #084c84;
}

.navbar > .container {
  height: 68px;
}

.navbar__logo {
  width: 40px;
  height: 40px;
  position: relative;
  top: 2px;
}

.navbar__title {
  font-size: 24px;
  color: var(--white);
  margin-left: 10px;
  font-weight: bold;
  transition: 0.3s;
}

.navbar__brand:hover .navbar__title {
  color: var(--first-color-alt);
}

.navbar__contact {
  margin-left: 50px;
}

.contact-phone-icon {
  color: var(--first-color-alt);
  font-weight: 500;
  font-size: 20px;
}

.contact-phone-number {
  margin-left: 12px;
  color: var(--white);
  font-size: 20px;
}

.navbar__menu {
  margin-right: 24px;
}

.navbar__link {
  margin: 0 12px;
  font-size: 24px;
  color: var(--white);
  transition: 0.3s;
}

.navbar__link:hover {
  color: var(--first-color-alt);
}

@media screen and (max-width: 991px) {
  .navbar__contact,
  .navbar__menu {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .navbar > .container {
    height: 88px;
  }
  .navbar__title {
    font-size: 26px;
  }
  .navbar__logo {
    width: 52px;
    height: 52px;
  }
}

@media screen and (min-width: 1200px) {
  .navbar__link {
    margin: 0 26px;
  }
  .navbar__menu {
    margin-right: 44px;
  }
}

@media screen and (min-width: 1560px) {
  .navbar > .container {
    height: 114px;
  }
  .navbar__title {
    font-size: 32px;
  }
  .navbar__logo {
    width: 80px;
    height: 80px;
  }
  .navbar__menu {
    margin-right: 44px;
  }

  .navbar__link {
    margin: 0 32px;
    font-size: 28px;
  }
  .contact-phone-number,
  .contact-phone-icon {
    font-size: 24px;
  }
}
