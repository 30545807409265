.product-section {
  background-color: #f4f4f4;
  padding-top: 28px;
}

.product-section .container {
  position: relative;
  z-index: 1;
}

.product__bg-left {
  position: absolute;
  left: 0;
  top: 180px;
  width: 11%;
  height: 75%;
  background-image: url(../../../assets/images/Path_2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.product__bg-right {
  position: absolute;
  right: 0;
  top: 120px;
  width: 28%;
  height: 77%;
  background-image: url(../../../assets/images/Path_1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.product-list {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  flex-direction: column;
  align-items: stretch;
  margin-top: 34px;
}

.product-list .card {
  box-sizing: border-box;
  margin: 10px auto;
  padding: 28px 32px;
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: var(--white);
  box-shadow: 0px 9px 92px 0px rgba(0, 0, 0, 0.11);
  text-align: center;
}

.product-list .card__img {
  width: 120px;
  height: 120px;
  margin-bottom: 5px;
}

.product-list .card__description {
  color: var(--title-color);
  font-size: 18px;
  margin-bottom: 18px;
}

.product-list .card__detail {
  color: var(--text-color-light);
  font-size: 18px;
}

.product-section__footer {
  margin-top: 32px;
  margin-bottom: 44px;
}

.product-section__footer > div:nth-child(1) {
  text-align: center;
  color: var(--text-color);
  font-size: 24px;
  font-weight: bold;
}

.product-section__footer > div:nth-child(2) {
  margin-top: 28px;
  text-align: center;
  color: var(--text-color-light);
  font-size: 20px;
}

@media screen and (max-width: 992px) {
  .product-section {
    padding-top: 100px;
    margin-top: -100px;
  }
  .product__bg-left,
  .product__bg-right {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .product-list {
    flex-direction: row;
  }

  .product-list .card {
    margin: 6px;
    padding: 25px 20px;
  }
}

@media screen and (min-width: 992px) {
  .product-section {
    padding-top: 96px;
  }
  .product-list {
    margin-top: 60px;
  }
  .product-list .card {
    margin: 10px;
  }
  .product-section__footer {
    margin-top: 54px;
    margin-bottom: 88px;
  }
  .product-section__footer > div:nth-child(1) {
    font-size: 30px;
  }
  .product-section__footer > div:nth-child(2) {
    font-size: 24px;
  }
}

@media screen and (min-width: 1560px) {
  .product-list {
    margin-top: 100px;
  }
  .product-list .card {
    margin: 14px;
    padding: 24px 32px;
  }

  .product-list .card__detail,
  .product-list .card__description {
    font-size: 21px;
  }

  .product-section__footer {
    margin-top: 92px;
    margin-bottom: 120px;
  }
  .product-section__footer > div:nth-child(1) {
    font-size: 44px;
  }
  .product-section__footer > div:nth-child(2) {
    font-size: 38px;
  }
}
