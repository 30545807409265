@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600&display=swap');

/* Variable Css */
:root {
  --first-color: #3592db;
  --first-color-alt: #95deff;
  --first-color-disabled: #6686a1;
  --first-color-dark: #28618f;
  --first-color-deep-dark: #084d85;
  --title-color: #333;
  --text-color: #666;
  --text-color-light: #999;
  --input-placeholder-color: #ccc;
  --white: #fff;
}

/* Basic */
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

button:active:not(:disabled) {
  position: relative;
  top: 1px;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Reusable Css Classes */
.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.relative {
  position: relative;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media screen and (min-width: 1560px) {
  .container {
    width: 1440px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 68px;
  box-sizing: border-box;
}

@media screen and (min-width: 992px) {
  .App {
    padding-top: 88px;
  }
}

@media screen and (min-width: 1560px) {
  .App {
    padding-top: 114px;
  }
}
