.doing {
  text-align: center;
}

.doing-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.doing-content p {
  font-size: 16px;
  color: var(--text-color-light);
}

.doing-icon {
  width: 80px;
  height: 80px;
  margin-top: 48px;
}

.doing-message {
  font-size: 12px;
  margin-top: 12px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  color: var(--first-color-dark);
}

.doing-message img {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.doing-delete-your-warnings {
  color: var(--first-color-dark);
}

.doing-delete-time {
  color: tomato;
}

@media screen and (min-width: 992px) {
  .doing-icon {
    width: 138px;
    height: 138px;
    margin-top: 76px;
  }

  .doing-message {
    margin-top: 20px;
    font-size: 14px;
  }


  .doing-message img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}
