.error-order {
  text-align: center;
}

.error-content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.error-content p {
  font-size: 16px;
  color: var(--text-color-light);
}

.error-icon {
  width: 80px;
  height: 80px;
  margin-top: 48px;
}

@media screen and (min-width: 992px) {
  .error-icon {
    width: 138px;
    height: 138px;
    margin-top: 76px;
  }
}
