.Layout {
  position: relative;
}

.Layout-content {
  position: relative;
  z-index: 1;
  flex-direction: column;
  margin-bottom: 50px;
}

@media  screen and (min-width: 992px) {
    .Layout-content {
    flex-direction: row;
    margin-bottom: 0;
  }
}
