
/* Reset swal button */

.swal2-actions {
  width: 100%;
  justify-content: flex-end;
  padding-right: 1.6em;
  padding-left: 1.6em;
}

.swal2-actions button {
  border-radius: 20px !important;
  flex: 1;
}

.swal2-cancel {
  background-color: #dbdcdd !important;
  color: #333 !important;
}

.swal2-icon.swal2-warning--custom .swal2-icon-content,
.swal2-icon.swal2-warning--custom {
  background-color: transparent;
  color: transparent;
  border: 0;
}

.swal2-icon.swal2-warning--custom {
  width: 60px;
  height: 60px;
  border-width: 0px;
  margin: 35px auto 10px;

  background-size: 100% 100%;
  background-image: url(./assets/images/delete-account/Icon_warning_fill.png);
}