.reminder-title {
  color: var(--first-color);
  font-size: 24px;
}

.reminder-subtitle {
  color: var(--text-color-light);
  font-size: 18px;
}

.reminder-content {
  list-style: none;
  padding-left: 0;
}

.reminder-content > li {
  padding-left: 34px;
  position: relative;
  margin-bottom: 12px;
  color: var(--text-color);
}

.reminder-content > li > span {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: var(--first-color-dark);
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (min-width: 992px) {
  .reminder-title {
    font-size: 36px;
  }
  .reminder-subtitle {
    font-size: 26px;
  }

  .reminder-content > li {
    padding-left: 40px;
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 28px;
  }

  .reminder-content > li > span {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 24px;
  }
}
