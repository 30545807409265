
.request-form-title {
  color: var(--title-color);
  font-size: 16px;
}

.request-form-group {
  height: 44px;
  border-radius: 44px;
  border: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 16px;
  font-size: 16px;
  overflow: hidden;
}

.request-form-group:focus-within {
  border-color: var(--first-color-dark);
}

.request-form-group > input {
  height: 40px;
  border: 0;
  outline: none;
  flex: 1;
  font-size: inherit;
  color: var(--title-color);
  background-color: transparent;
}

.request-form-group > input::placeholder {
  color: var(--input-placeholder-color)
}

.request-form-group .phone-country-code {
  margin-right: 10px;
  color: var(--first-color);
}

.request-form-checkbox {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: var(--text-color-light);
}

.request-form-checkbox > input {
  margin-right: 6px;
}

input[type='checkbox']{
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: #fff;
  -webkit-appearance:none;
  border: 1px solid #C0C4CC;
  outline: none;
}
input[type=checkbox]:checked{
    border: none;
    background: url("../../assets/images/delete-account/Icon_checked.png") no-repeat center;
    background-size: contain;
}

.request-form-send-verification-code {
  margin-top: 14px;
  margin-bottom: 24px;
  text-align: right;
}

.request-form-send-verification-code button {
  border: 0;
  background-color: transparent;
  color: var(--first-color);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.form-group-vcode {
  padding-right: 40px;
}

#vCodeButton {
  background-color: #fff;
  border-radius: 0px 66px 66px 0px;
  width: 160px;
  height: 66px;
  margin-right: -40px;
  overflow: hidden;
}

@media screen and (min-width: 992px) {

  .request-form-title {
    font-size: 20px;
    text-align: center;
    margin-top: 48px;
  }

  .request-form-group {
    height: 66px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .request-form-group > input {
    height: 60px;
  }

  .request-form-checkbox {
    font-size: 14px;
    user-select: none;
    margin-bottom: 22px;
  }

}
